import React, { lazy, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import Highcharts, { Series, SeriesOptionsType } from 'highcharts/highstock';
import {
    ChartCompareLineSeriesTypeRaw,
    ChartOverlaySeriesTypeRaw,
    ChartPoint,
    ChartSeriesType,
    IntervalChartChartingInterval,
    MetricChartChartingInterval,
    MetricChartDataType,
    MetricChartState,
    MetricChartStateLegend,
    MetricChartViewsMode,
    SeriesType,
} from './index';
import useBundleTranslation from 'i18n';
import {
    buildMovingAverageSeries,
    buildStatisticalSeries,
    ChartSeriesProcessParams,
    getProjectionSeries,
    getStoplightSeries,
    getTargetSeries,
    processMetricSeries,
} from './seriesBuilder';
import { Chart as HChart, PlotSeriesPointOptions } from 'highcharts';
import RegularSeriesTooltip, { ChartTooltipProps } from './tooltip/RegularSeriesTooltip';
import { ElementType } from 'components/element-viewer';
import TooltipContainer from 'components/chart/TooltipContainer';
import getEventSeries from 'components/metric/chart/series/getEventSeries';
import chartRedrawAlerts from 'components/metric/chart/draw/chartRedrawAlerts';
import { getAlertSeries } from 'components/metric/chart/series/getAlertSeries';
import {
    AdditionalToDrawType,
    clearChart,
    drawSVG,
    RangeToDrawType,
    refreshSVGRangesGroup,
} from 'components/metric/chart/draw/drawRanges';
import adjustCanvas from 'components/metric/chart/draw/adjustCanvas';
import chartRedrawEvents from 'components/metric/chart/draw/chartRedrawEvents';
import { getAnnotationSeries } from 'components/metric/chart/series/getAnnotationSeries';
import chartRedrawAnnotations from 'components/metric/chart/draw/chartRedrawAnnotations';
import MetricChartTopBar from 'components/metric/MetricChartTopBar';
import { useQuery } from '@tanstack/react-query';
import { metricAPI } from 'api/viewer/metric';
import { getCompareLineSeries } from 'components/metric/chart/series/getCompareLineSeries';
import { getOverlaySeries } from 'components/metric/chart/series/getOverlaySeries';
import { MetricChartTools } from 'components/metric/chart/metricChartTools';
import AnnotationTooltip, { ChartAnnotationTooltipProps } from 'components/metric/chart/tooltip/AnnotationTooltip';
import EventTooltip, { ChartEventTooltipProps } from 'components/metric/chart/tooltip/EventTooltip';
import AlertTooltip, { ChartAlertTooltipProps } from 'components/metric/chart/tooltip/AlertTooltip';
import { EventCalendarType } from 'components/metric/MetricViewer';
import PreviewTooltip from 'components/metric/chart/tooltip/PreviewTooltip';
import useComponentReady from 'components/report-content/hooks/useComponentReady';
import { DisplayMask } from 'components/dataset-viewer';
import formatValue from 'tools/formatValue';
import { THUMBNAIL_HEIGHT, THUMBNAIL_WIDTH } from 'tools/tools';
import { useTheme } from '@mui/material/styles';
import { lighten } from '@mui/system/colorManipulator';
import { alpha, Theme } from '@mui/material';
import { useBlendColors } from 'hooks/useDesign';
import { Params as UrlParams } from '@remix-run/router/dist/utils';
import { useParams } from 'react-router-dom';

const Chart = lazy(() => import('../../chart/Chart'));
export const CHART_DEFAULT_HEIGHT = 400;
export const CHART_MARGIN_TOP = 30;

export interface ChartSVGPointEvents {
    mouseOver: (seriesType: SeriesType, point: ChartPoint) => void;
    mouseOut: (seriesType: SeriesType, point: ChartPoint) => void;
    click: (seriesType: SeriesType, point: ChartPoint) => void;
}

export type ElementDisplayContextType = 'viewer' | 'preview' | 'screenshot';

export function formatChartValue(
    obj: any,
    side: 'left' | 'right',
    chartData: MetricChartDataType,
    chartState: React.MutableRefObject<MetricChartState>
): string {
    const getMaskDataById = (id: number) => chartData.masksData.find((mask) => mask.display_mask_id == id);
    const leftMask = getMaskDataById(chartState.current?.left_mask ?? 0);
    const rightMask = getMaskDataById(chartState.current?.right_mask ?? 0);

    let prefix = chartData.metadata.prefix ?? '';
    let dm: DisplayMask | false = false;

    if (side == 'left' && leftMask) {
        dm = leftMask;
        prefix = leftMask.prefix;
    }
    if (side == 'right' && rightMask) {
        dm = rightMask;
        prefix = rightMask.prefix;
    }

    // if (
    //     dm == null &&
    //     'undefined' != typeof this.params.metadata &&
    //     side == 'right' &&
    //     typeof this.params.metadata.display_mask_right_series != 'undefined' &&
    //     'undefined' == typeof this.params.metadata.metrics
    // ) {
    //     var overlays = this.state.get('overlays');
    //     for (var i in this.params.metadata.display_mask_right_series) {
    //         if (this.params.metadata.display_mask_right_series.hasOwnProperty(i)) {
    //             var tmp = this.params.metadata.display_mask_right_series[i].id.split('_');
    //             tmp = tmp[1] + '_' + tmp[2];
    //             for (var j in overlays) {
    //                 if (overlays.hasOwnProperty(j)) {
    //                     if (overlays[j].element_id + '_' + overlays[j].segment_value_id == tmp) {
    //                         dm = this.params.metadata.display_mask_right_series[i].display_mask;
    //                         prefix = this.params.metadata.display_mask_right_series[i].prefix;
    //                         break;
    //                     }
    //                 }
    //             }
    //         }
    //         if (dm != null) break;
    //     }
    //     if (dm === false) {
    //         return formatValue(obj.value, prefix);
    //     }
    // }

    if (!dm) {
        if (chartData.metadata.display_mask) {
            dm = chartData.metadata.display_mask;
            if (chartData.metadata?.currency_mask_ind == 'Y') {
                dm.prefix = prefix;
            }
        } else if (chartData.metadata.metrics) {
            for (let i in chartData.metadata.metrics) {
                if (!chartData.metadata.metrics.hasOwnProperty(i)) {
                    continue;
                }
                const metric = chartData.metadata.metrics[i];
                if (
                    !dm ||
                    (metric.element_id == chartData.metadata.multi_chart_stoplight_metric_element_id &&
                        metric.segment_value_id == chartData.metadata.multi_chart_stoplight_segment_value_id)
                ) {
                    if ('left' == side && 1 == metric.axis_number) {
                        dm = metric.display_mask;
                    }
                    if ('right' == side && 2 == metric.axis_number) {
                        dm = metric.display_mask;
                    }
                }
            }
            if (!dm) {
                return formatValue(obj.value, prefix);
            }
        } else {
            return formatValue(obj.value, prefix);
        }
    }

    if (!dm) {
        return obj.value;
    }

    switch (dm.show_amount_as) {
        case 'Thousands':
            obj.value /= 1000;
            break;
        case 'Millions':
            obj.value /= 1000000;
            break;
        case 'Billions':
            obj.value /= 1000000000;
            break;
        case 'Hundredths':
            obj.value *= 100;
            break;
        case 'Percent':
            obj.value *= 100;
            break;
    }

    let formattedValue = obj.value.toFixed(dm.display_precision_digits ? dm.display_precision_digits : 0);

    const reg =
        dm.decimal_point_delimiter?.length == 0
            ? new RegExp('(\\B(?=(\\d{3})+(?!\\d)))', 'g')
            : new RegExp('(\\B(?=(\\d{3})+(?=\\' + dm.decimal_point_delimiter + ')(?!\\d)))', 'g');

    if (dm.thousands_delimiter) {
        formattedValue = formattedValue.replace(reg, dm.thousands_delimiter);
    }

    if (dm.prefix) formattedValue = dm.prefix + formattedValue;
    if (dm.suffix) formattedValue += dm.suffix;

    return formattedValue;
}

function useIconSeries(
    chartData: MetricChartDataType,
    chartState: MetricChartState,
    elementInfo: ElementType,
    theme: Theme,
    chart?: HChart,
    yAxisVisible?: boolean
): {
    alertSeries: Array<ChartSeriesType>;
    eventSeries: Array<ChartSeriesType>;
    annotationSeries: Array<ChartSeriesType>;
} {
    const alertSeries = useMemo(
        () =>
            chart
                ? getAlertSeries(
                      chartData.seriesData,
                      chartData.alertSeriesData,
                      chartState.legend,
                      chart,
                      theme,
                      elementInfo.row
                  )
                : [],
        [chartData, chart, chart?.series?.length ?? 0, chartState.legend?.alerts, yAxisVisible]
    );
    const eventSeries = useMemo(
        () =>
            chart
                ? getEventSeries(
                      chartData.seriesData,
                      chartData.eventData,
                      chartState.legend,
                      chart,
                      elementInfo.row,
                      theme
                  )
                : [],
        [chartData, chart, chart?.series?.length ?? 0, chartState.legend?.events, yAxisVisible]
    );
    const annotationSeries = useMemo(
        () =>
            chart
                ? getAnnotationSeries(
                      chartData.seriesData,
                      chartData.annotationSeriesData,
                      chartState.legend,
                      chart,
                      elementInfo.row,
                      theme
                  )
                : [],
        [chartData, chart, chart?.series?.length ?? 0, chartState.legend?.annotations, yAxisVisible]
    );

    return { alertSeries, eventSeries, annotationSeries };
}

export default function MetricChartWrapper({
    chartData,
    elementInfo,
    segmentValueId,
    chartState,
    updateChartStateLegend,
    updateChartStateRange,
    updateChartView,
    width,
    userChartOverlay,
    elementDisplayType = 'viewer',
    manualCalendars,
    onChartClick,
    refetch,
}: {
    chartData: MetricChartDataType;
    elementInfo: ElementType;
    segmentValueId: number;
    chartState: MetricChartState;
    updateChartStateLegend: (id: string, legendState: MetricChartStateLegend) => void;
    updateChartStateRange: (chartingInterval: MetricChartChartingInterval) => void;
    updateChartView: (newView: MetricChartViewsMode) => void;
    width: number;
    userChartOverlay: number;
    elementDisplayType?: ElementDisplayContextType;
    manualCalendars: Array<EventCalendarType>;
    onChartClick?: () => void;
    refetch: () => void;
}) {
    const urlParams: UrlParams = useParams();
    const forScreenShot = elementDisplayType == 'screenshot';
    const isThumbnail = forScreenShot && window.location.href.includes('thumbnail');
    const hasUCO = window.location.href.includes('uco');
    let chartView = chartData.state.view ?? 'standard';
    if ((forScreenShot && !hasUCO) || !chartData.availableViews.includes(chartView)) {
        chartView = 'standard';
    }
    const [actualView, setActualView] = useState<MetricChartViewsMode>(chartView);
    useEffect(() => {
        updateChartView(actualView);
    }, [actualView]);

    // Used for Chart Tooltip
    const [hoveredChartPoint, setHoveredChartPoint] = useState<ChartPoint | null>(null);
    const [selectedChartPoint, setSelectedChartPoint] = useState<ChartPoint | null>(null);
    const [pointTooltipSeries, setPointTooltipSeries] = useState<ChartSeriesType | null>(null);
    // Highcharts callbacks will not work with State in correct way, useRef to pass actual values into point handles
    const isPointSelected = useRef<boolean>(false);
    const appTheme = useTheme();

    useEffect(() => {
        isPointSelected.current = selectedChartPoint != null;
    }, [selectedChartPoint]);

    useEffect(() => {
        const view = urlParams?.view;
        if (view) {
            if (view === 'std_deviation') {
                setActualView('statistical');
                return;
            }
            setActualView(view as MetricChartViewsMode);
        }
    }, [urlParams]);

    const handlePointClick = function (point: ChartPoint, series: ChartSeriesType) {
        if (typeof onChartClick != 'undefined') {
            onChartClick();
        } else {
            if (
                series.seriesType == 'main' &&
                (!point?.metadata ||
                    typeof point.metadata.metric_instance_id == 'undefined' ||
                    typeof point.y == 'undefined' ||
                    point.y == null)
            ) {
                return;
            }
            setSelectedChartPoint(point);
            setPointTooltipSeries(series);
        }
    };

    const handlePointMouseOver = function (point: ChartPoint, series: ChartSeriesType) {
        if (isPointSelected.current) {
            return;
        }
        setHoveredChartPoint(point);
        setPointTooltipSeries(series);
    };

    const handlePointMouseOut = function () {
        if (isPointSelected.current) {
            return;
        }
        setHoveredChartPoint(null);
    };

    const handlePointClose = function () {
        setSelectedChartPoint(null);
        setHoveredChartPoint(null);
    };

    const { t } = useBundleTranslation(['components/metric/chart']);

    const handleViewChange = function (view: MetricChartViewsMode) {
        setActualView(view);
    };

    // Called from MetricChartTopBar on range selection button click
    const handleSetChartRange = function (chartingInterval: MetricChartChartingInterval) {
        // chart?.xAxis[0].setExtremes(min - 1, max + 1);
        // TODO: ?
        // if (!(this.settings.isImageGeneration || this.settings.isPreview))
        //     this.state.set('range', { interval_unit: unit, interval_value: value });
        updateChartStateRange(chartingInterval);
    };

    const [chart, setChart] = useState<HChart>();

    useEffect(() => {
        if (!chart) {
            return;
        }

        const extremes = chart.xAxis[0].getExtremes();
        if (!chartState.range) {
            chart?.xAxis[0].setExtremes(extremes.dataMin, extremes.dataMax);
        }

        if (chartState.range?.interval_unit == 'custom') {
            chart?.xAxis[0].setExtremes(chartState.range.min_value, chartState.range.max_value);
            return;
        }

        const interval: IntervalChartChartingInterval | undefined = chartData.metricChartOptions.chartingIntervals.find(
            (c) => {
                return (
                    c.chartingIntervalUnit != 'custom' &&
                    c.chartingIntervalUnit == chartState.range?.interval_unit &&
                    c.chartingIntervalValue == chartState.range.interval_value
                );
            }
        ) as IntervalChartChartingInterval | undefined;
        if (!interval) {
            return;
        }

        let min = extremes.max,
            max = extremes.max;

        const tmp = MetricChartTools.getRangeBorders(
            min,
            max,
            extremes.dataMin,
            interval.chartingIntervalUnit,
            interval.chartingIntervalValue
        );

        if (tmp && tmp.min) min = tmp.min;
        if (tmp && tmp.max) max = tmp.max;
        if (extremes.dataMin > min) min = extremes.dataMin;
        if (extremes.dataMax < tmp.max) max = extremes.dataMax;
        if (min < extremes.dataMax) {
            chart?.xAxis[0].setExtremes(min - 1, max + 1);
            // handleSetChartRange(interval, min - 1, max + 1);
        }
    }, [chart, chartState.range]);

    const chartStateRef = useRef(chartState);
    useEffect(() => {
        chartStateRef.current = chartState;
    }, [chartState]);

    useEffect(() => {
        if (!chart) {
            return;
        }
        setTimeout(() => chart.redraw(), 100);
    }, [chartState.left_mask, chartState.right_mask, chart]);

    const handleLegendSeriesClick = (series: Series) => {
        const opt = series.options as SeriesOptionsType & ChartSeriesType;
        updateChartStateLegend(opt.id, { visible: series.visible });
        return false;
    };

    const [maxChartXValue, setMaxChartXValue] = useState<number>(0);

    useEffect(() => {
        if (!forScreenShot) {
            return;
        }
        chart?.xAxis[0].setExtremes(chart?.xAxis[0].getExtremes().userMin, maxChartXValue);
    }, [maxChartXValue]);

    const themeSettings = {
        chart: {
            plotBorderColor: useBlendColors(alpha(appTheme.palette.text.primary, 0.08)),
            backgroundColor: appTheme.palette.background.default,
        },
        legend: {
            itemStyle: {
                color: appTheme.palette.primary.main,
            },
            itemHoverStyle: {
                color: lighten(appTheme.palette.primary.main, 0.24),
            },
            itemHiddenStyle: {
                color: useBlendColors(alpha(appTheme.palette.text.primary, 0.64)),
            },
        },
    };

    const [yAxisVisible, setYAxisVisible] = useState(false);
    const basicChartOptions = useMemo<Highcharts.Options>(() => {
        let yAxisTitle = elementInfo.row.metricYAxisLabel ?? elementInfo.row.elementDashboardName;
        const result: Highcharts.Options = {
            credits: { enabled: false },
            chart: {
                height: isThumbnail ? THUMBNAIL_HEIGHT : CHART_DEFAULT_HEIGHT,
                width: isThumbnail ? THUMBNAIL_WIDTH : width,
                animation: false,
                type:
                    elementInfo.row.metricChartDisplayType == 'bar' ? 'column' : elementInfo.row.metricChartDisplayType,
                marginTop: CHART_MARGIN_TOP,
                // height: settings.height ? settings.height : 700,
                style: {
                    fontFamily: 'Arial, helvetica, sans-serif',
                },
                events: {
                    redraw: function () {
                        // Hide tooltip
                        handlePointClose();
                        const svgRangesGroup = refreshSVGRangesGroup(this);
                        let additionalIcons: AdditionalToDrawType = {
                            points: [],
                            ranges: [],
                        };
                        additionalIcons = chartRedrawAlerts(this, mainSeriesRef, additionalIcons, svgIconsEvents);
                        additionalIcons = chartRedrawAnnotations(this, mainSeriesRef, additionalIcons, svgIconsEvents);
                        additionalIcons = chartRedrawEvents(this, mainSeriesRef, additionalIcons, svgIconsEvents);

                        additionalIcons.ranges.forEach((range: RangeToDrawType<ChartPoint>) => {
                            drawSVG(appTheme, this, 'range', range, svgRangesGroup, svgIconsEvents);
                        });

                        additionalIcons.points.forEach((point) => {
                            drawSVG(appTheme, this, 'point', point, svgRangesGroup, svgIconsEvents);
                        });

                        adjustCanvas(this, additionalIcons);
                        setMaxChartXValue(this.xAxis[0].getExtremes().dataMax);
                        setYAxisVisible(!isNaN(this.yAxis[0].toPixels(0, false)));
                    },
                    click: () => (typeof onChartClick != 'undefined' ? onChartClick() : false),
                },
                plotBorderColor: themeSettings.chart.plotBorderColor,
                backgroundColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
                    stops: [
                        [0, themeSettings.chart.backgroundColor],
                        [1, themeSettings.chart.backgroundColor],
                    ],
                },
            },
            // scrollbar: { enabled: false },
            navigator: {
                enabled: !forScreenShot,
                adaptToUpdatedData: true,
                xAxis: {
                    labels: {
                        formatter: function () {
                            return MetricChartTools.formatXAxisLabel(this, chartData.metricChartOptions);
                        },
                    },
                },
            },
            rangeSelector: { enabled: false },
            tooltip: { enabled: false },
            plotOptions: {
                line: {
                    animation: false,
                    marker: {
                        radius: 1,
                        enabled: true,
                    },
                    dataGrouping: {
                        enabled: false,
                    },
                },
                series: {
                    animation: false,
                    turboThreshold: 0,
                    // showInNavigator: true,
                    events: {
                        show: function (event) {},
                        hide: function (event) {},
                        legendItemClick: function (event) {
                            handleLegendSeriesClick(event.target);
                            return false;
                        },
                    },
                },
                column: {
                    pointPlacement: chartData.metricChartOptions.measurementIntervalUnit == 'quoter' ? 'on' : undefined,
                    minPointLength: 3,
                    dataGrouping: {
                        approximation: 'average',
                        enabled: !(
                            (
                                (elementInfo.row.metricChartDisplayType == 'bar' ||
                                    elementInfo.row.metricChartDisplayType == 'column') &&
                                true
                            )
                            //'Y' === this.settings.metric_change_bar_color_on_value_ind
                        ),
                    },
                },
            },
            // TODO: ?
            legend: {
                align: 'left',
                enabled: !isThumbnail,
                itemStyle: {
                    font: '13px Arial, helvetica, sans-serif',
                    color: themeSettings.legend.itemStyle.color,
                },
                itemHoverStyle: {
                    color: themeSettings.legend.itemHoverStyle.color,
                },
                itemHiddenStyle: {
                    color: themeSettings.legend.itemHiddenStyle.color,
                },
                itemMarginTop: 2,
                itemMarginBottom: 2,
                maxHeight: 200,
                itemWidth: 200,
                width: 600,
                itemDistance: 15,
            },
            xAxis: {
                visible: !isThumbnail,
                minRange: 1,
                ordinal: false,
                // scalable: false,
                // crosshairs: {
                //     dashStyle: 'dash',
                // },
                labels: {
                    enabled: !isThumbnail,
                    formatter: function () {
                        return MetricChartTools.formatXAxisLabel(this, chartData.metricChartOptions);
                    },
                },
                min: null,
                max: null,
            },
            yAxis: [
                {
                    showEmpty: true,
                    labels: {
                        enabled: !isThumbnail,
                        formatter: function () {
                            return formatChartValue(this, 'left', chartData, chartStateRef);
                        },
                    },
                    min:
                        elementInfo.row.metricStartYAxisFromZeroInd && elementInfo.row.metricChartDisplayType == 'line'
                            ? 0
                            : undefined,
                    opposite: false,
                    title: {
                        useHTML: true,
                        style: { whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: '12px', width: 320 },
                        text: yAxisTitle,
                    },
                },
                {
                    labels: {
                        enabled: !isThumbnail,
                        formatter: function () {
                            return formatChartValue(this, 'left', chartData, chartStateRef);
                        },
                    },
                    min:
                        elementInfo.row.metricStartYAxisFromZeroInd && elementInfo.row.metricChartDisplayType == 'line'
                            ? 0
                            : undefined,
                    opposite: true,
                },
            ],
        };

        if (isThumbnail) {
            if (Array.isArray(result.yAxis)) {
                delete result.yAxis[0].title;
                delete result.yAxis[1].title;
            } else {
                delete result?.yAxis?.title;
            }
        }

        return result;
    }, [elementInfo, width, chartStateRef.current]);

    const getSeriesPointOptions = (series: ChartSeriesType): PlotSeriesPointOptions => {
        return {
            events: {
                mouseOver: function () {
                    // @ts-ignore
                    handlePointMouseOver(this, series);
                },
                mouseOut: () => handlePointMouseOut(),
                click: function () {
                    // @ts-ignore
                    handlePointClick(this, series);
                },
            },
        };
    };

    const svgIconsEvents: ChartSVGPointEvents = {
        mouseOver: (seriesType: SeriesType, point: ChartPoint) =>
            handlePointMouseOver(point, { seriesType: seriesType } as ChartSeriesType),
        mouseOut: () => handlePointMouseOut(),
        click: (seriesType: SeriesType, point: ChartPoint) =>
            handlePointClick(point, { seriesType: seriesType } as ChartSeriesType),
    };

    // Prepare series for chart
    const mainSeries = useMemo(() => {
        const series = processMetricSeries(chartData, chartState, elementInfo.row, isThumbnail);
        return series.map((series) => {
            series.point = getSeriesPointOptions(series);
            return series;
        });
    }, [chartData, chartState.legend]);
    const mainSeriesRef = useRef<Array<ChartSeriesType>>();
    useEffect(() => {
        mainSeriesRef.current = mainSeries;
    }, [mainSeries]);

    const chartSeriesParams: ChartSeriesProcessParams = {
        series: chartData.seriesData[0],
        projectionData: chartData.projectionData,
        metricChartOptions: chartData.metricChartOptions,
        targetSeriesData: chartData.targetSeriesData,
        chartState: chartState,
        t: t,
        // @ts-ignore
        chart: chart,
        theme: appTheme,
    };

    const movingAverageSeries = useMemo(
        () => buildMovingAverageSeries(chartSeriesParams),
        [chartData, chartState.legend]
    );
    const statisticalSeries = useMemo(() => buildStatisticalSeries(chartSeriesParams), [chartData, chartState.legend]);
    const targetSeries = useMemo(() => getTargetSeries(chartSeriesParams), [chartData, chartState.legend]);

    // Get CompareLines Data based on 'chartState'
    const compareLinesList = chartState.compareLines.map((line) => line.id);
    const compareLineKey = 'compare_lines_' + compareLinesList.join('_');
    const { data: compareLinesData } = useQuery<Array<ChartCompareLineSeriesTypeRaw>, Error>([compareLineKey], () => {
        return metricAPI.getCompareLines(elementInfo.row.elementId, segmentValueId, compareLinesList);
    });
    const compareLineSeries = useMemo(
        () =>
            !compareLinesData || !chart
                ? []
                : getCompareLineSeries(compareLinesData, chartState.compareLines, chartState.legend),
        [compareLinesData, chartState.compareLines, chartState.legend, chart]
    );

    // Get Overlays Data based on 'chartState'
    const overlaysList = chartState.overlays.map((overlay) => overlay.element_id + '_' + overlay.segment_value_id);
    const overlaysKey = 'overlays_' + overlaysList.join('_');
    const { data: overlaysData } = useQuery<Array<ChartOverlaySeriesTypeRaw>, Error>([overlaysKey], () => {
        return metricAPI.getOverlays(elementInfo.row.elementId, segmentValueId, chartState.overlays);
    });
    const overlaySeries = useMemo(
        () =>
            !overlaysData || !chart
                ? []
                : getOverlaySeries(overlaysData, chartState.overlays, chartState.legend, getSeriesPointOptions),
        [overlaysData, chartState.overlays, chartState.legend, chart]
    );

    const [stoplightSeries, setStoplightSeries] = useState<Array<ChartSeriesType>>([]);
    const [projectionSeries, setProjectionSeries] = useState<ChartSeriesType>();

    const { alertSeries, eventSeries, annotationSeries } = useIconSeries(
        chartData,
        chartState,
        elementInfo,
        appTheme,
        chart,
        yAxisVisible
    );

    const [eventsRendered, setEventsRendered] = useState(false);
    useEffect(() => {
        if (!chart || !eventSeries.length) {
            return;
        }

        if (eventSeries[0].visible && !eventsRendered) {
            updateChartStateLegend(eventSeries[0].id, { visible: false });
            setTimeout(() => updateChartStateLegend(eventSeries[0].id, { visible: true }), 100);
        }
        setEventsRendered(true);
    }, [chart, eventSeries]);

    useEffect(() => {
        // Remove ChartRanges lines & Icons
        clearChart();
    }, [alertSeries, eventSeries]);

    // Manage Actual View
    useLayoutEffect(() => {
        let seriesList = mainSeries.concat(movingAverageSeries).concat(compareLineSeries).concat(overlaySeries);

        if (alertSeries?.[0]?.data?.length) {
            seriesList = seriesList.concat(alertSeries);
        }
        if (eventSeries?.[0]?.data?.length) {
            seriesList = seriesList.concat(eventSeries);
        }
        if (annotationSeries?.[0]?.data?.length) {
            seriesList = seriesList.concat(annotationSeries);
        }

        switch (actualView) {
            case 'statistical':
                seriesList.push(statisticalSeries);
                break;
            case 'stoplight':
                stoplightSeries.forEach((s) => seriesList.push(s));
                break;
            case 'projection':
                if (projectionSeries) {
                    seriesList.push(projectionSeries);
                }
                break;
            case 'target':
                targetSeries.forEach((s) => seriesList.push(s));
                break;
        }

        const yPlotLines = [];
        if (elementInfo.row.metricShowMaxEverOnChartInd || elementInfo.row.metricShowMinEverOnChartInd) {
            let min: number | null = null;
            let max: number | null = null;
            mainSeries[0].data.forEach((point) => {
                if (min == null || min > Number(point.y)) {
                    min = point.y ?? min;
                }
                if (max == null || max < Number(point.y)) {
                    max = point.y ?? max;
                }
            });

            // TODO : color?
            let width = 1,
                color = 'green',
                dashStyle = 'Solid';

            if (elementInfo.row.metricShowMaxEverOnChartInd && null != max && !isThumbnail) {
                yPlotLines.push({
                    value: max,
                    width: width,
                    color: color,
                    dashStyle: dashStyle,
                    label: {
                        text: t('chart_label_maximum_ever'),
                        y: -5,
                        style: {
                            color: color,
                        },
                    },
                });
            }

            if (elementInfo.row.metricShowMinEverOnChartInd && null != min && !isThumbnail) {
                yPlotLines.push({
                    value: min,
                    width: width,
                    color: color,
                    dashStyle: dashStyle,
                    label: {
                        text: t('chart_label_minimum_ever'),
                        y: -5,
                        style: {
                            color: color,
                        },
                    },
                });
            }
        }

        let yAxis;
        if (Array.isArray(basicChartOptions.yAxis)) {
            //@ts-ignore
            basicChartOptions.yAxis[0] = { ...basicChartOptions.yAxis[0], plotLines: yPlotLines };
            yAxis = basicChartOptions.yAxis;
        } else {
            yAxis = { ...basicChartOptions.yAxis, plotLines: yPlotLines };
        }

        // @ts-ignore
        setChartOptions({ ...basicChartOptions, yAxis: yAxis, series: isThumbnail ? mainSeries : seriesList });
    }, [
        chartData,
        actualView,
        stoplightSeries,
        alertSeries,
        eventSeries,
        annotationSeries,
        compareLineSeries,
        overlaySeries,
        width,
    ]);

    const [chartOptions, setChartOptions] = useState<Highcharts.Options>(basicChartOptions);

    const afterChartCreated = function (chart: HChart) {
        setChart(chart);
    };
    useComponentReady(elementDisplayType == 'screenshot' || elementDisplayType == 'preview' ? chart : false);

    useEffect(() => {
        if (typeof chart == 'undefined') {
            return;
        }

        setStoplightSeries(getStoplightSeries(chartSeriesParams));
        setProjectionSeries(getProjectionSeries(chartSeriesParams));
    }, [chart, chartState.legend]);

    const pointToShow = selectedChartPoint ? selectedChartPoint : hoveredChartPoint;

    // const deleteAnnotation = (id: string) => {
    //     const newState: Array<ChartAnnotationSeriesTypeRaw> = structuredClone(chartData.annotationSeriesData);
    //
    //     newState.forEach((series) => {
    //         const indexRange = series.data.range.findIndex((annotation) => String(annotation.mydata?.key) == id);
    //         if (indexRange != -1) {
    //             series.data.range.splice(indexRange, 1);
    //         }
    //         const indexPoint = series.data.point.findIndex((annotation) => String(annotation.mydata?.key) == id);
    //         if (indexPoint != -1) {
    //             series.data.point.splice(indexPoint, 1);
    //         }
    //     });
    //
    //     setChartData((prevState) => {
    //         return { ...prevState, annotationSeriesData: newState.slice() };
    //     });
    //     // //console.log(chartData.annotationSeriesData);
    // };

    let tooltip = null;
    if (chart && pointToShow && pointTooltipSeries && pointToShow.metadata && elementDisplayType != 'screenshot') {
        const tooltipProps: ChartTooltipProps = {
            chart: chart,
            point: pointToShow,
            series: pointTooltipSeries,
            elementInfo: elementInfo,
            manualCalendars: manualCalendars,
            segmentValueId: segmentValueId,
            actualView: actualView,
            t: t,
            isSelected: selectedChartPoint != null,
            onClose: handlePointClose,
            userChartOverlay: userChartOverlay,
            refetch: refetch,
        };

        if (elementDisplayType == 'preview') {
            tooltip = <PreviewTooltip {...tooltipProps} />;
        } else {
            switch (pointTooltipSeries?.seriesType) {
                case 'main':
                case 'overlay':
                    tooltip = <RegularSeriesTooltip {...tooltipProps} />;
                    break;
                case 'annotation':
                    tooltip = <AnnotationTooltip {...(tooltipProps as ChartAnnotationTooltipProps)} />;
                    break;
                case 'event':
                    tooltip = (
                        <EventTooltip
                            {...({
                                ...tooltipProps,
                                permittedCalendars: chartData.permittedCalendars,
                            } as ChartEventTooltipProps)}
                        />
                    );
                    break;
                case 'alert':
                    tooltip = <AlertTooltip {...(tooltipProps as ChartAlertTooltipProps)} />;
                    break;
                default:
                    console.log(pointTooltipSeries?.seriesType);
            }
        }
    }
    return (
        <>
            {chart && chart.xAxis?.[0]?.getExtremes()?.dataMax > 0 && (
                <MetricChartTopBar
                    onViewChange={handleViewChange}
                    views={chartData.availableViews}
                    chartingIntervals={chartData.metricChartOptions.chartingIntervals}
                    measurementIntervalUnit={chartData.metricChartOptions.measurementIntervalUnit}
                    selectedInterval={chartState.range}
                    actualView={actualView}
                    maxXValue={maxChartXValue}
                    minXValue={chart.xAxis[0].getExtremes().dataMin ?? 0}
                    setSelectedInterval={handleSetChartRange}
                    elementDisplayType={elementDisplayType}
                    chart={chart}
                />
            )}
            {actualView == 'stoplight' && (
                <div
                    style={{ textAlign: 'center' }}
                    dangerouslySetInnerHTML={{ __html: chartData.stoplightLegend }}
                ></div>
            )}
            {useMemo(
                () => (
                    <span>
                        <Chart afterChartCreated={afterChartCreated} chartOptions={chartOptions} />
                    </span>
                ),
                [chartOptions]
            )}
            {chart && tooltip && (
                <TooltipContainer point={pointToShow} chart={chart}>
                    {tooltip}
                </TooltipContainer>
            )}
        </>
    );
}
